/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
  body {
    padding: 0;
  }

  .sidebar-wrapper {
    position: static;
    width: inherit;
  }

  .wrapper {
    display: block;
  }

  .main-wrapper {
    padding: 30px;
  }

  .main-wrapper .time {
    position: static;
    display: block;
    margin-top: 5px;
  }

  .main-wrapper .upper-row {
    margin-bottom: 0;
  }
}
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .skillset .level-title {
    display: inline-block;
    float: left;
    width: 30%;
    margin-bottom: 0;
  }

  .skillset .level-bar {
    display: inline-block;
    width: 70%;
    float: left;
    position: relative;
    top: 1px;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* Ex-Large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
}
