/* ======= Base ======= */
body {
  font-family: "Roboto", sans-serif;
  color: $text-color-secondary;
  background: $smoky-white;
  font-size: 14px;
  padding: 30px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

a {
  color: darken($theme-color, 15%);
  &:hover {
    text-decoration: underline;
    color: darken($theme-color, 30%);
  }
  &:focus {
    text-decoration: none;
  }
}

p {
  line-height: 1.5;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  background: $theme-color;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  @include box-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
}

.sidebar-wrapper {
  grid-column: span 3;
  order: 2;
  background: $theme-color;
  color: #fff;
  a {
    color: #fff;
  }
  .profile-container {
    padding: 30px;
    //background: darken($theme-color, 10%);
    background: rgba(0, 0, 0, 0.2);
    text-align: center;
    color: #fff;
  }
  .name {
    font-size: 20px;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .tagline {
    color: rgba(256, 256, 256, 0.6);
    font-size: 16px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }
  .profile {
    margin-bottom: 15px;
  }

  .avatar {
    //used for profile image
    max-width: 100px;
    margin-bottom: 15px;
    border: 0px solid #fff;
    border-radius: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  .contact-list {
    .fas,
    .fab,
    .far {
      margin-right: 5px;
      font-size: 18px;
      vertical-align: middle;
    }
    li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .container-block {
    padding: 30px;
  }
  .container-block-title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 15px;
  }
  .degree {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 5px;
  }
  .education-container {
    .item {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .meta {
      color: rgba(256, 256, 256, 0.6);
      font-weight: 500;
      margin-bottom: 0px;
      margin-top: 0;
    }
    .time {
      color: rgba(256, 256, 256, 0.6);
      font-weight: 500;
      margin-bottom: 0px;
    }
  }
  .languages-container {
    .lang-desc {
      color: rgba(256, 256, 256, 0.6);
    }
  }

  .languages-list {
    margin-bottom: 0;
    li {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .interests-list {
    margin-bottom: 0;
    li {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.main-wrapper {
  grid-column: span 7;
  background: #fff;
  padding: 60px;
  .section-title {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
    color: darken($theme-color, 15%);
    position: relative;
    margin-top: 0;
    margin-bottom: 20px;
  }
  .section {
    margin-bottom: 60px;
  }

  .experiences-section,
  .educations-section {
    .item {
      margin-bottom: 30px;
    }
  }
  .upper-row {
    position: relative;
    overflow: hidden;
    margin-bottom: 2px;
  }
  .job-title,
  .degree {
    color: $text-color;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
  }
  .time {
    position: absolute;
    right: 0;
    top: 0;
    color: $text-grey;
  }
  .company,
  .university {
    margin-bottom: 10px;
    color: $text-grey;
  }

  .project-title {
    font-size: 16px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 5px;
    color: darken($theme-color, 15%);
  }
  .projects-section {
    .intro {
      margin-bottom: 30px;
    }
    .item {
      margin-bottom: 15px;
    }
  }

  .publication-title {
    font-size: 16px;
    font-weight: 400;
    color: $text-color;
    margin-bottom: 1px;
  }
  .publication-authors {
    font-style: italic;
    color: $text-grey;
    margin-bottom: 1px;
  }
  .publications-section {
    .intro {
      margin-bottom: 30px;
    }
    .item {
      margin-bottom: 15px;
    }
  }
}

.skillset {
  .item {
    margin-bottom: 15px;
    overflow: hidden;
  }
  .level-title {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 12px;
  }
  .level-bar {
    height: 12px;
    background: $smoky-white;
  }
  .level-bar-inner {
    height: 12px;
    background: lighten($theme-color, 15%);
  }
}

.footer {
  padding-top: 30px;
  .copyright {
    line-height: 1.6;
    color: $text-color-secondary;
    font-size: 13px;
  }

  .fa-heart {
    color: $heart;
  }
}
